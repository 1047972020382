import React from "react"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Stack from "../../../../../components/stack"
import Paragraph from "../../../../../components/paragraph"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "jahreszeiten" })

  return (
    <LayoutCourse
      as="form"
      navigation={navigation}
      footer={
        <CourseFooterNext
          title="Bist du bereit?"
          label="Los Geht's"
          to="/kurse/jahreszeiten/01-jahreszeitengedicht/nomen"
        />
      }
    >
      <Seo title="Worten & Wortarten" />
      <Stack>
        <Heading as="h1" level={2}>
          Worte & Wortarten
        </Heading>
        <Paragraph>
          Ein Dichter wählt die Worte seiner Gedichte ganz bewusst. Welche Worte
          und Wortarten hat Hölderlin hier verwendet?
        </Paragraph>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
